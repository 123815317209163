import React, { useState } from "react";
import About from "./components/About";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import Work from "./components/Work";


function App() {
  // The handleVideoEnd function will be called when the video ends. It will set the showSite state to true.
  const [showSite, setShowSite] = useState(true);

  const handleVideoEnd = () => {
    setShowSite(true);
  };

  // The App component is the parent component that holds all the other components.
  return (
    <div className="relative w-full h-screen bg-[#343740]">
      {!showSite && (
        <div className="absolute inset-0 z-50 flex items-center jsutify-center bg-[#3C3F48]">
          <video className="w-full h-auto max-h-screen object-cover" autoPlay muted playsInline onEnded={handleVideoEnd}>
            <source src="/Logo_Animation_3.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {showSite && (
        <div className="bg-[#343740]">
          <Navbar />
          <Home />
          <Skills />
          <Work />
          <About />

          <footer className="mt-auto bg-[#343740] text-center text-gray-300 py-2">
            <p>&copy; {new Date().getFullYear()} Aaron Shannon. All rights reserved.</p>
          </footer>
        </div>
      )}
    </div>
  );
}

export default App;
